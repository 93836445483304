import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from './en_translation.json';
import translationEs from './es_translation.json';
import translationFr from './fr_translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources:{
            en: { translation: translationEn},
            es: { translation: translationEs},
            fr: { translation: translationFr},
        },
        lng: 'es', //feault language
        fallbackLng: 'en', //In case is no transaltion available
        interpolation: {
            escapeValue: false, //React already handles XSS escape
        }
    })

export default i18n;