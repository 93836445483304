import React from "react";
import '../styles/Contact.css';
import Section from "./Section";
import { withTranslation } from "react-i18next";

class Contact extends React.Component
{
    render()
    {
        const { t } = this.props
        return (
            <Section id="section-contact_us" legend={t("Contact")}>
                <p>
                    <a href="mailto:admin@sabeludia.com">{t("Write_us_mail")+t('doble_dot')} admin@sabeludia.com</a>
                </p>
            </Section>
        )
    }
}

export default withTranslation()(Contact)