import React from "react";
import '../styles/AppsList.css';
import Section from "./Section";
import File from "./File";
import { withTranslation } from "react-i18next";

class AppsList extends React.Component
{
    render()
    {
        const langCodes = {"en":0, "es":1, "fr":2}
        const { t, i18n } = this.props;
        let langcode = 0 //english to compatibility with way to manage traductions in older created apps
        if(["es","en","fr"].includes(i18n.language))
        {
            langcode = langCodes[i18n.language]
        }
        
        const all_apps = [
            ["Matesguay","https://www.sabeludia.com/apps/matesguay","es", t('matesguay_description')],
            [t("Silent_world"),"https://www.sabeludia.com/apps/silent_world/?l="+langcode,"en, es, fr", t('silent_world_description')],
            ["Repspace","https://www.sabeludia.com/apps/repspace","es", t('repspace_description')],
            [t("random_student"),"https://www.sabeludia.com/apps/random_student","es", t('random_student_description')],
            [t("ludiquation"),"https://www.sabeludia.com/apps/ludiquation_slab.js","en", t('ludiquation_description')],
            [t("learn&tag_scheme"),"https://www.sabeludia.com/apps/learn&tag_scheme","en", t('learn&tag_scheme')],
            [t("learn&complete"),"https://www.sabeludia.com/apps/learn&complete","fr", t('learn&complete_description')],
            [t("Portfolio"),"https://www.sabeludia.com/apps/portfolio","en", t('portfolio_descritpion')],
        ]
        
        return (
            <Section id="section-sabeludia_apps" legend={t("Sabeludia_Apps")}>
                <div className="AppsList-files_containers">
                    {all_apps.map((item, index) => (
                        <File 
                            href={item[1]} 
                            appname={item[0]} 
                            namecolor={index%2 === 0 ? "primary" : "secondary"} 
                            key={index}
                            description={item[3]}
                            languages={item[2]}
                        />
                    ))}                    
                </div>
            </Section>
        )
    }
}
export default withTranslation()(AppsList)