import React from "react";
import '../styles/LinksList.css';
import Section from "./Section";
import { withTranslation } from "react-i18next";

class LinksList extends React.Component
{
    render()
    {
        const {t} = this.props
        return (
            <Section id="section-external_links" legend={t("External_Links")}>
                <a className="external_link primary" href="https://strawpoll.com/" target="_blank" rel="noreferrer">Strawpoll</a>
                <a className="external_link secondary" href="https://www.canva.com/" target="_blank" rel="noreferrer">Canva</a>
                <a className="external_link primary" href="https://www.daypo.com/" target="_blank" rel="noreferrer">Daypo</a>
            </Section>
        )
    }
}

export default withTranslation()(LinksList)