import logo from '../logo.svg';

import '../styles/App.css';
import AppsList from './AppsList';
import Contact from './Contact';
import Infos from './Infos';
import Language from './Language';
import LinksList from './LinksList';
import Nav from './Nav';
import { useTranslation } from 'react-i18next';

function App() {
   const { t } = useTranslation()
   return (
      <div className="App" id="home">
         <Nav />
         <header className="App-header">
            <img src={logo} className="App-logo" alt="logo of sabeludía" />
            <p className='App-header-title'>
               <span style={{color:'#34898f'}}>ab</span>e<span style={{color:'#34898f'}}>lud</span>ía
               <br />
               <span style={{color:'#34898f'}}>{t('s_avvy')}</span> {t('ludic')}
            </p>
         </header>
         <main>
            <Language />
            <AppsList />
            <LinksList />
            <Contact />
            <Infos />
         </main>
      </div>
   );
}

export default App;
