import React from "react";
import '../styles/Section.css';

import home_icon from "../img/house_own.svg";

export default class Section extends React.Component
{
    render()
    {
        return (
            <section className="Section-container" id={this.props.id}>
                <fieldset>
                    <legend>{this.props.legend}</legend>
                    <div style={{textAlign:"start"}}>
                        <a href="#home">
                            <img className="clr-white" src={home_icon} alt="to home icon" width={64}/>
                        </a>
                    </div>
                    {this.props.children}
                    <div style={{textAlign:"end"}}>
                        <a href="#home">
                            <img className="clr-white invert-y" src={home_icon} alt="to home icon" width={64}/>
                        </a>
                    </div>
                </fieldset>
            </section>
        )
    }
}