import React from "react";
import '../styles/Nav.css';

import language_icon from '../img/langage_own_icon.svg';
import account_icon from '../img/locker_own.svg';
import sab_files from '../img/files_sabeludia.svg';
import link_icon from '../img/link_own.svg';
//import price_icon from '../img/coin_own.svg';
import contact_icon from '../img/contact_own.svg';
import info_icon from '../img/info_own.svg';
//import calendar_icon from '../img/calendar_own.svg';
import Authentication from "./Authentication";
import { withTranslation } from "react-i18next";

class Nav extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {displayValue: "none"}
        this.showAuthentication = this.showAuthentication.bind(this)
    }
    
    showAuthentication(state=true)
    {
        if(!state){ this.setState({ displayValue: "none" }); return }
        this.setState({ displayValue: "flex" })
    }
    
    render()
    {
        return (
            <nav>
                {/*space in corners*/}
                <div className="space space_corner pos-top-left dgreen"></div>
                <div className="space space_corner pos-top-right dpurple"></div>
                <div className="space space_corner pos-bottom-right lpurple"></div>
                <div className="space space_corner pos-bottom-left lgreen"></div>

                {/*spaces on top of the sreen*/}
                <a href="#section-language_choice" className='space space-h space-top-1 lgreen'>
                    <img alt="language icon" src={language_icon} width={64}/>
                </a>
                
                <a href="#section-account_access" onClick={this.showAuthentication} className='space space-h space-top-2 lpurple'>
                    <img alt="account icon" src={account_icon} width={64}/>
                </a>

                {/*spaces on bottom of the sreen*/}
                <a href="#home" className='space space-h space-bottom-1 dgreen'>
                    {/*<img alt="calendar icon" src={calendar_icon} width={64}/>*/}
                </a>

                <a href="#home" className='space space-h space-bottom-2 dpurple'>
                    {/*<img alt="price icon" src={price_icon} width={64}/>*/}
                </a>

                {/*spaces on the left of the sreen*/}
                <a href="#section-contact_us" className='space space-v space-left-1 lgreen'>
                    <img alt="contact icon" src={contact_icon} width={64}/>
                </a>
                
                <a href="#section-info" className='space space-v space-left-2 dgreen'>
                    <img alt="info icon" src={info_icon} width={64}/>
                </a>
                
                {/*spaces on the right of the sreen*/}
                <a href="#section-sabeludia_apps" className='space space-v space-right-1 lpurple'>
                    <img alt="sabeludia apps icon" src={sab_files} width={64}/>
                </a>
                <a href="#section-external_links" className='space space-v space-right-2 dpurple'>
                    <img alt="links icon" src={link_icon} width={64}/>
                </a>

                {/*Authentication zone*/}
                <Authentication displayValue={this.state.displayValue} showAuth={this.showAuthentication} />
            </nav>
        )
    }
}

export default withTranslation()(Nav)