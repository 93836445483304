import React from "react";
import '../styles/File.css';

import sabfile_icon from "../img/sabfile.svg";

import { withTranslation } from "react-i18next";

class File extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {displayValue: 'none'}
        this.showDescription = this.showDescription.bind(this)
        this.hideDescription = this.hideDescription.bind(this)
    }

    hideDescription()
    {
        this.setState({displayValue: "none"})
    }

    showDescription()
    {
        this.setState({displayValue: "flex"})
    }

    render()
    {
        const {t} = this.props
        return (
            <div className="File-container">
                <div className="File-icons">
                    <a className="File-link" href={this.props.href} target="_blank" rel="noreferrer">
                        <img src={sabfile_icon} alt={"go to the app "+this.props.appname} width={64}/>
                    </a>
                    <button onClick={this.showDescription}>?</button>
                </div>
                <a className={this.props.namecolor} href={this.props.href}>{this.props.appname}</a>

                <div className="File-protector" style={{display:this.state.displayValue}}>
                    <fieldset>
                        <legend>
                            {this.props.appname}
                            <button onClick={this.hideDescription}>×</button>
                        </legend>
                        <p>
                            <span>{t('Languages_available')}{t('doble_dot')} </span>
                            {this.props.languages}
                        </p>
                        <p>
                            <span>{t('Description')+t('doble_dot')} </span>
                            {this.props.description}
                        </p>
                    </fieldset>
                </div>
            </div>
        )
    }
}
export default withTranslation()(File)